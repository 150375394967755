import React from "react";
import { useRoutes, Navigate, useNavigate } from "react-router-dom";
import { Layout } from "../view/layout";
import { UserPage } from "src/view/user/User.tsx";
import { NotFoundPage } from "src/view/components/NotFound.tsx";
// import { WelcomePage } from "src/view/welcome/Welcome.tsx";

// const RedirectToHome = () => {
//   const navigate = useNavigate();

//   React.useEffect(() => {
//     navigate("/welcome");
//   }, [navigate]);

//   return null;
// };

const GetRouters = () => {
  return useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "",
          element: <NotFoundPage></NotFoundPage>,
        },
        // {
        //   path: "welcome",
        //   element: <WelcomePage></WelcomePage>,
        // },
        {
          path: "/:miner_v_oid/:gene_id",
          element: <UserPage></UserPage>,
        },
        {
          path: "*",
          element: <NotFoundPage />,
        },
      ],
    },
  ]);
};
export default GetRouters;
