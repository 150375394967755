import "./NoutFound.scss";
import { useNavigate } from "react-router-dom";
export const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div className={"not-found"}>
      <div className={"not-icon"}></div>
      <div className={"description"}>
        {/* <span onClick={() => navigate('/')}>Back Home</span> */}
      </div>
    </div>
  );
};
