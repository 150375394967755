import "./User.scss";

import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import { useWriteContract, useBalance, useAccount, useEstimateGas, useDisconnect } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { parseEther } from "viem";

import { UserDistribution } from "src/api";
import { canContinuePaging, truncateNumber } from "src/utils/utils.ts";
import { useMessage } from "src/view/components/MessageContext.tsx";
import { Loading } from "src/view/components/loading.tsx";
import { useGlobalState } from "src/hook/useGlobalState.ts";

// dialog
import { CancelDialog } from "./dialog/cancelDialog.tsx";
import { BuyBitlifeDialog } from "./dialog/buyBitlifeDialog.tsx";
import { useBalanceOf, useBalanceBnb } from "./useRead.tsx"; // Read Contract

// static
import TOKEN_ABI from "./token_abi.json";
import orderNull from "img/user/order_null.png";
import { ethers } from "ethers";

// page url : /miner_v_oid/gene_id ==>  /0201/21424
export const UserPage = function () {
  const { miner_v_oid, gene_id } = useParams();
  const account = useAccount(); // 用来判断是否连接钱包
  const { open } = useWeb3Modal();
  const avatarRef = useRef<HTMLDivElement>(null);
  const { showMessage } = useMessage();
  const { writeContractAsync } = useWriteContract();
  const { address } = useGlobalState()
  // var
  const [orders, setOrders] = useState<any[]>([]);
  const [cancelOrderId, setCancelOrderId] = useState("0");
  const [bitlifeDetails, setBitlifeDetails] = useState<any>(null);
  const [quantity, setQuantity] = useState(0);
  const [distributions, setDistributions] = useState<any>(null);

  // dialog
  const [cancelVisible, setCancelVisible] = useState<boolean>(false);
  const [buyVisible, setBuyVisible] = useState<boolean>(false);
  // loading
  const { isLoading, setIsLoading } = useGlobalState();

  // dialog ::::::::: buy
  const closeBuyDialog = () => {
    setBuyVisible(false);
  };
  // 初始化购买时的变量
  const handlerResetVar = () => {
    setBuyVisible(false);
    setIsLoading(false);
    setQuantity(0);
    pageNum.current = 1;
    apiGetOrderList(address);
    apiGetBitlifeDetails(String(miner_v_oid), String(gene_id));
  };
  const usdtontract = useRef<any>("");
  const { refetch } = useBalanceOf(usdtontract.current, address);
  // const useBalanceBnbObj = useBalanceBnb(address);

  const payToken = async (
    chainId: any,
    usdtContractAddress: any,
    receiverAddress: any,
    needPayAmt: any
  ) => {
    // 检测是否有足够的金额
    usdtontract.current = usdtContractAddress;
    const userBalanceRes: any = await refetch();
    const userBalance =
      userBalanceRes.status === "success" ? userBalanceRes.data : "0";
    // USDT是否满足
    if (BigInt(userBalance) < parseEther(needPayAmt)) {
      handlerResetVar();
      return showMessage("Insufficient balance : USDT");
    }
    // 是否有BNB
    // const userBnbBalanceRes = await useBalanceBnbObj.refetch();

    // gas computed
    const iface = new ethers.Interface(TOKEN_ABI)
    const data: any = iface.encodeFunctionData('transfer', [receiverAddress, parseEther(needPayAmt)])
    gasParams.current = data
    estimateGas.refetch().then((res: any) => {
      if (res.isSuccess) {
        const newGas = parseInt((parseInt(res.data.toString()) * 1.3).toString())
        // 付钱
        writeContractAsync({
          address: usdtContractAddress,
          abi: TOKEN_ABI,
          functionName: "transfer",
          args: [receiverAddress, parseEther(needPayAmt)],
          gasPrice: chainId === "97" ? 3000001234n : 1000001234n,
          gas: BigInt(newGas)
        })
          .then((res: any) => {
            handlerResetVar();
          })
          .catch((error: any) => {
            console.error("error", error);
            showMessage(
              "Please cancel your order and re-order if you have not paid"
            );
            handlerResetVar();
          });
        // End;
      }
    })
  };
  // gas computed
  const gasParams = useRef<any | null>(null)
  const estimateGas = useEstimateGas({
    to: usdtontract.current,
    data: gasParams.current
  })
  const clickBuyNext = useCallback(() => {
    setIsLoading(true);
    setBuyVisible(false);
    const formData = {
      ethAddress: address,
      stockId: bitlifeDetails.stockId,
      buyNum: quantity,
    };
    UserDistribution.placeBitlifeOrder(formData).then(async (res: any) => {
      if (res.code === 200) {
        const resData = res.data;
        showMessage("Payment in progress");
        payToken(
          resData.chainId,
          resData.usdtContractAddress,
          resData.receiverAddress,
          resData.needPayAmt
        );
      } else {
        showMessage(res.message);
        handlerResetVar();
      }
    });
  }, [address, quantity, bitlifeDetails]);

  // dialog:cancle
  const closeCancelDialog = () => {
    setCancelVisible(false);
  };
  const clickCancelNext = useCallback(() => {
    UserDistribution.cancelOrder(address, cancelOrderId).then((res: any) => {
      showMessage(res.code === 200 ? "Cancelled" : res.message);
      pageNum.current = 1;
      apiGetOrderList(address);
      apiGetBitlifeDetails(String(miner_v_oid), String(gene_id));
    });
    setCancelVisible(false);
  }, [address, cancelOrderId]);

  // APIS
  // API: 获取分销商信息
  const loading = useRef(false);
  const apiGetBitlifeDetails = (minerId: string, geneId: string) => {
    if (!loading.current) {
      loading.current = true;
      UserDistribution.queryDistributionBitLifeInfo(minerId, geneId).then(
        (res: any) => {
          loading.current = false;
          if (res.code === 200) {
            // distributionAvatar	string 分销商头像
            // distributionNickName	string  分销商昵称

            // stockId	integer($int64) 库存id

            // bitLifeGifUrl	string  bitlife gif 图片链接
            // availableStockNum	integer($int64) 库存数量
            // price	string 零售价

            // expectHashrate	string  预期可达到的Hashrate
            // oneDayHashRate	string  1DHashRate
            // sevenDayHashRate	string 7DHashRate
            // fourteenDayHashRate	string 14DHashRate
            const resData = res.data;
            const info: any = {
              distribution_avatar: resData.distributionAvatar,
              distribution_nick_name: resData.distributionNickName,
              distribution_miner_v_oid: resData.minerVOId,
            };
            setDistributions(info);

            const details: any = {
              stockId: resData.stockId,
              bitLifeGifUrl: resData.bitLifeGifUrl,
              availableStockNum: resData.availableStockNum,
              price: resData.price,

              expectHashrate: resData.expectHashrate,
              oneDayHashRate: resData.oneDayHashRate,
              sevenDayHashRate: resData.sevenDayHashRate,
              fourteenDayHashRate: resData.fourteenDayHashRate,
            };
            setBitlifeDetails(details);
          }
        }
      );
    }
  };
  // handler
  const handlerQuantityMinus = () => {
    setQuantity(quantity >= 1 ? quantity - 1 : 0);
  };
  const handlerQuantityPlus = () => {
    if (bitlifeDetails && bitlifeDetails.availableStockNum) {
      setQuantity(
        quantity < bitlifeDetails.availableStockNum
          ? quantity + 1
          : bitlifeDetails.availableStockNum
      );
    }
  };
  const changeAddressValue = (value: any) => {
    let inputValue = value.target.value;
    const maxValue = bitlifeDetails.availableStockNum
    inputValue = inputValue.replace(/[^0-9]/g, '');
    if (inputValue > maxValue) {
      inputValue = maxValue;
    }
    value.target.value = inputValue;
    setQuantity(Number(value.target.value))
  }

  useEffect(() => {
    apiGetBitlifeDetails(String(miner_v_oid), String(gene_id));
  }, []);
  useEffect(() => {
    setIsLoading(bitlifeDetails ? false : true);
  }, [bitlifeDetails]);

  // paging:分页
  const pageNum = useRef(1);
  const pageSize = useRef(15);
  const [total, setTotal] = useState(0);
  const dataContainer = useRef<HTMLDivElement>(null);
  const pageLoading = useRef(false);
  // API: 获取我的订单 (通过用户身份+基因型唯一ID)
  const apiGetOrderList = (ethAddress: string) => {
    if (address) {
      UserDistribution.queryMyOrderHistory(
        address,
        pageNum.current,
        pageSize.current
      ).then((res: any) => {
        if (res.code === 200) {
          const resData = res.data;
          setOrders(resData.list);
          setTotal(resData.total);
        }
      });
    }
  };
  useEffect(() => {
    pageNum.current = 1;
    if (address) {
      apiGetOrderList(address);
    } else {
      setOrders([]);
      setTotal(0);
    }
  }, [address]);

  const handlePage = () => {
    if (
      !dataContainer.current ||
      pageLoading.current ||
      !canContinuePaging(pageNum.current, pageSize.current, total)
    )
      return;
    const { scrollTop, scrollHeight, clientHeight } = dataContainer.current;
    const isAtBottom = scrollTop + clientHeight >= scrollHeight - 300;
    if (isAtBottom) {
      pageLoading.current = true;
      pageNum.current++;
      UserDistribution.queryMyOrderHistory(
        address,
        pageNum.current,
        pageSize.current
      ).then((res: any) => {
        if (res.code === 200) {
          const resData = res.data;
          setOrders(orders.concat(resData.list));
          setTotal(resData.total);
          pageLoading.current = false;
        }
      });
      //
    }
  };
  useEffect(() => {
    if (dataContainer.current && orders.length) {
      dataContainer.current.addEventListener("scroll", handlePage);
    }
  }, [orders.length]);
  const { disconnect } = useDisconnect()

  useEffect(() => {
    if (!localStorage.getItem('userToken')) {
      disconnect()
    }
  }, [localStorage.getItem('userToken')]);
  return (
    <div ref={avatarRef} className={"user-page"}>
      {/* {isLoading && <Loading></Loading>} */}
      <div className={"user-container"}>
        {distributions && (
          <div className={"distribution-area"}>
            <div className="distribution-avatar">
              <img src={distributions.distribution_avatar} alt="" srcSet="" />
            </div>
            <div className="distribution-names">
              <div className="distribution-level">
                <span>{distributions.distribution_nick_name}</span>
                {/* <span className="separator">/</span> */}
                {/* <span>{distributions.level_2}</span> */}
              </div>
              <div className="distribution-id">
                ID：{distributions.distribution_miner_v_oid}
              </div>
            </div>
          </div>
        )}
        <div className={"bitlife-area"}>
          <div className="bitlife-show">
            {bitlifeDetails && (
              <img src={bitlifeDetails.bitLifeGifUrl} alt="" srcSet="" />
            )}
          </div>
          <div className="bitlife-info">
            {/* 价格 + 数量 */}
            <div className="bitlife-stock">
              <div className="price">
                <div className="price-show">
                  <p>Standard Price/BitLife</p>
                  <div className="price-stock">
                    <strong>
                      {bitlifeDetails ? bitlifeDetails.price : "-"} USDT
                    </strong>
                    <div className="stock-info">
                      ( Stock:
                      {bitlifeDetails ? bitlifeDetails.availableStockNum : "-"})
                    </div>
                  </div>
                </div>
              </div>
              <div className="stock">
                <p>Quantity</p>
                <div className="pick-value">
                  <span
                    className="action left"
                    onClick={handlerQuantityMinus}
                  ></span>
                  <input onChange={(e) => changeAddressValue(e)} value={quantity} className="value" />
                  <span
                    className="action right"
                    onClick={handlerQuantityPlus}
                  ></span>
                </div>
              </div>
            </div>

            {/* 库存 */}
            <div className="bitlife-settlement">
              {/* {!connected && <div className="connect">Wallet Connect</div>} */}

              {bitlifeDetails && (
                <div className="settlement-details">
                  <div className="order-total">
                    <p>Total</p>
                    <strong>{quantity * bitlifeDetails.price} USDT</strong>
                  </div>
                  <div className="order-action">
                    <button
                      disabled={quantity === 0}
                      onClick={() => {
                        if (account && account.address) {
                          setBuyVisible(true);
                        } else {
                          open();
                        }
                      }}
                    >
                      BUY
                    </button>
                  </div>
                </div>
              )}
            </div>

            {/* Hash */}
            <div className="bitlife-hashs">
              {bitlifeDetails && (
                <ul>
                  <li>
                    <p className="title">Expected Hashrate</p>
                    <p className="detail">
                      {bitlifeDetails.expectHashrate || "-"}
                    </p>
                  </li>
                  <li>
                    <p className="title">1D Hashrate</p>
                    <p className="detail">{bitlifeDetails.oneDayHashRate}</p>
                  </li>
                  <li>
                    <p className="title">7D Hashrate</p>
                    <p className="detail">{bitlifeDetails.sevenDayHashRate}</p>
                  </li>
                  <li>
                    <p className="title">14D Hashrate</p>
                    <p className="detail">
                      {bitlifeDetails.fourteenDayHashRate}
                    </p>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className={"orders-area"}>
          <div className="order-header">
            <strong>My order</strong>
            {/* <span>
              * If you have already made the payment, please don't cancel the
              order.
            </span> */}
          </div>

          <div className="orders scroll-content" ref={dataContainer}>
            {/* 无订单时 */}
            {orders.length ? (
              orders.map((item, index) => {
                return (
                  <div className="order-item" key={item.orderId}>
                    <div className="item item0 item_avatars">
                      <div className="item_avatar">
                        <img src={item.bitlifeGifUrl} alt="" srcSet="" />
                      </div>
                    </div>
                    <div className="item item1">{item.orderId}</div>
                    <div className="item item2">
                      {item.buyNum} Bitlife
                    </div>

                    {/* 订单状态 */}
                    {item.orderStatus === 0 && (
                      <div className="item item3 warning">
                        Pending Confirmation
                      </div>
                    )}
                    {item.orderStatus === 1 && (
                      <div className="item item3 warning">
                        Pending Distribution
                      </div>
                    )}
                    {item.orderStatus === 2 && (
                      <div className="item item3">Confirmed</div>
                    )}
                    {item.orderStatus === 3 && (
                      <div className="item item3 success">Expired </div>
                    )}
                    {/* 用户可操作 */}
                    {item.orderStatus === 0 && (
                      <div className="item item4 error ">
                        <span
                          className="action"
                          onClick={() => {
                            setCancelOrderId(item.orderId);
                            setCancelVisible(true);
                          }}
                        >
                          Cancel Order
                        </span>
                      </div>
                    )}
                    {item.orderStatus === 1 && (
                      <div className="item item4"></div>
                    )}
                    {item.orderStatus === 2 && (
                      <div className="item item4 ">
                        <a
                          href={
                            item.chainId === "97"
                              ? `https://testnet.bscscan.com/tx/${item.transferHashId}`
                              : `https://bscscan.com/tx/${item.transferHashId}`
                          }
                          target="_blank"
                          className="action"
                          rel="noopener noreferrer"
                        >
                          View
                        </a>
                      </div>
                    )}
                    {item.orderStatus === 3 && (
                      <div className="item item4 error"></div>
                    )}
                  </div>
                );
              })
            ) : (
              <div className="nodata">
                <img src={orderNull} alt="" />
                <p>No Date</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* dialog */}

      {buyVisible && (
        <BuyBitlifeDialog
          title={"Confirmation of purchase"}
          btnLabel={"Confirm"}
          closeDialog={closeBuyDialog}
          clickNext={clickBuyNext}
          dataBitlifeQuantity={quantity}
          dataBitlifePrice={bitlifeDetails ? bitlifeDetails.price : 0}
          dataBitlifeUrl={bitlifeDetails ? bitlifeDetails.bitLifeGifUrl : 0}
        ></BuyBitlifeDialog>
      )}

      {cancelVisible && (
        <CancelDialog
          title={"Cancel Order"}
          btnLabel={"Confirm"}
          closeDialog={closeCancelDialog}
          clickNext={clickCancelNext}
        ></CancelDialog>
      )}
    </div>
  );
};
