import request from '../utils/request'

const URL = {
    QUERY_DISTRIBUTION_BITLIFE_INFO: '/minerOrder/queryDistributionBitLifeInfo',
    QUERY_MY_ORDER_HISTORY: "/minerOrder/queryMyOrderHistory",
    CANCEL_ORDER: "/minerOrder/cancelOrder",
    PLACE_BITLIFE_ORDER: "/minerOrder/placeBitlifeOrder",
}
export default {
    queryDistributionBitLifeInfo(minerVOId: string, geneId: string,) {
        return request.get(URL.QUERY_DISTRIBUTION_BITLIFE_INFO, {
            params: { minerVOId, geneId }
        })
    },

    queryMyOrderHistory(ethAddress: string, pageNum: number, pageSize: number) {
        return request.get(URL.QUERY_MY_ORDER_HISTORY, {
            params: { ethAddress, pageNum, pageSize }
        })
    },

    cancelOrder(ethAddress: string, orderId: string) {
        return request.get(URL.CANCEL_ORDER, {
            params: { ethAddress, orderId }
        })
    },

    placeBitlifeOrder(formData: any) {
        return request.post(URL.PLACE_BITLIFE_ORDER, formData)
    },
}