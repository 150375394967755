import "./cancelDialog.scss";
import React, { useEffect, useRef, useState } from "react";
interface createBitAgentProps {
  title: string;
  btnLabel: string;
  closeDialog: () => void;
  clickNext: () => void;
}
export const CancelDialog: React.FC<createBitAgentProps> = ({
  title,
  btnLabel,
  closeDialog,
  clickNext,
}) => {
  const clickConfirm = () => {
    if (btnLabel === "Confirm") {
      clickNext();
    }
  };
  return (
    <div className={"show-flex-box-r show-flex-center cancel-dialog-wrap"}>
      <div className={"show-flex-box-c create-bit-agent-content"}>
        <div className={"c-00E6FF f-28 mt-30 ml-40 dialog-title"}>{title}</div>
        <div
          className={"cursor-pointer close-icon"}
          onClick={closeDialog}
        ></div>
        <div
          className={"flex-1 mt-16 show-flex-box-c pl-40 pr-40 pt-20 content"}
        >
          <div className={"show-flex-box-r align-center mt-10"}>
            <div className={"f-20 c-FF4747 ml-4 tips"}>
              If you have already made the payment,{" "}
              <span className="tips-split"></span>
              please do not cancel the order.
            </div>
          </div>
          <div className={"show-flex-box-r show-flex-sa btn-list"}>
            <div
              className={
                "show-flex-box-r show-flex-center f-18 color-white cursor-pointer cancel-btn"
              }
              onClick={closeDialog}
            >
              Cancel
            </div>
            <div
              className={
                "show-flex-box-r show-flex-center f-18 color-white cursor-pointer confirm-btn"
              }
              onClick={clickConfirm}
            >
              {btnLabel}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
