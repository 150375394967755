import React, { useEffect, useRef, useState } from "react";

import { useReadContract, useBalance } from "wagmi";
import TOKEN_ABI from "./token_abi.json";

const useBalanceOf = (usdtContractAddress: any, address: any) => {
  const { data, isError, isLoading, isSuccess, refetch } = useReadContract({
    abi: TOKEN_ABI,
    address: usdtContractAddress,
    functionName: "balanceOf",
    args: [address],
  });
  return { data, isError, isLoading, isSuccess, refetch };
};
const useBalanceBnb = (address: any) => {
  const { data, isError, isLoading, isSuccess, refetch } = useBalance({
    address: address,
  });
  return { data, isError, isLoading, isSuccess, refetch };

  // return useBalance({ address: address });
};
export { useBalanceOf, useBalanceBnb };
