import "./loading.scss";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "src/hook/useGlobalState.ts";
export const Loading = () => {
  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useGlobalState();
  // useEffect(() => {
  //     const timer = setTimeout(() => {
  //         setIsLoading(false)
  //         navigate('/welcome');
  //     }, 40000);

  //     // 返回清理函数，组件卸载时清除定时器
  //     return () => {
  //         clearTimeout(timer);
  //     };
  // }, []);
  return (
    <div className={"w100 h100 show-flex-box-r show-flex-center loading-bg"}>
      <div className={"loading"}></div>
    </div>
  );
};
