import "./header.scss";
import Logo from "img/header/logo.png";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useGlobalState } from "src/hook/useGlobalState.ts";
import { getStr } from "src/utils/utils.ts";
import { networkList } from "src/hook/network.ts";
import { networkStatus } from "src/hook/networkStatus.ts";
import { useAccount, useAccountEffect, useSignMessage } from "wagmi";
import { useLocation } from "react-router-dom";
import { useMessage } from "src/view/components/MessageContext.tsx";
import { User } from "src/api";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
export const Header = function () {
  const navigate = useNavigate();
  const { open } = useWeb3Modal();
  //   const openWebSite = () => {
  //     navigate("/welcome");
  //   };
  const account = useAccount();
  const { pathname } = useLocation();
  const { setAddress, address } = useGlobalState();
  const { signMessage, data, isSuccess, isError } = useSignMessage();
  const { showMessage } = useMessage();

  useAccountEffect({
    onDisconnect() {
      const token = localStorage.getItem("userToken");
      if (token) {
        User.logout().then(() => {
          localStorage.removeItem("userToken");
          setAddress(null);

          // localStorage.removeItem('minerToken')
          // if (!pathname.includes('bitcell')) {
          //     navigate('/welcome')
          // }
        });
      }
    },
  });
  useEffect(() => {
    if (account.address !== undefined) {
      if (!address) {
        // const token = localStorage.getItem("minerToken");
        const token = localStorage.getItem("userToken");
        if (token) {
          setAddress(account.address);
        }
      } else {
        if (address !== account.address) {
          //   const token = localStorage.getItem("minerToken");
          const token = localStorage.getItem("userToken");
          if (token) {
            User.logout().then(() => {
              //   localStorage.removeItem("minerToken");
              localStorage.removeItem("userToken");
              setAddress(null);
              setAccountAddress("");
            });
          }
        }
      }
    }
  }, [account]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // login
  const [accountAddress, setAccountAddress] = useState("");

  const [messageTxt, setMessageTxt] = useState("");
  useEffect(() => {
    if (account.address !== undefined) {
      setAccountAddress(account.address);
    }
  }, [account]);
  useEffect(() => {
    if (!address) {
      setAccountAddress("");
    }
  }, [address]);
  const loading = useRef(false);
  const getMessage = (address: string) => {
    if (!loading.current) {
      loading.current = true;
      User.getMessage(address).then((res: any) => {
        if (res.code === 200) {
          signMessage({ message: res.data });
          setMessageTxt(res.data);
        }
      });
    }
  };
  useEffect(() => {
    if (isError) {
      loading.current = false;
    }
  }, [isError]);
  const loginAccount = () => {
    const formData = {
      ethAddress: accountAddress,
      hash: data,
      txt: messageTxt,
    };
    User.login(formData).then((res: any) => {
      if (res.code === 200) {
        // if (pathname === "/welcome") {
        //   navigate("/miner");
        // }
        // localStorage.setItem("minerToken", res.data.token);
        localStorage.setItem("userToken", res.data);
        setAddress(accountAddress);
      } else if (res.code === 309) {
        showMessage(res.message);
        navigate("*");
      } else {
        showMessage("Login timeout");
      }
    });
  };
  useEffect(() => {
    if (isSuccess) {
      loading.current = false;
      loginAccount();
    }
  }, [isSuccess]);
  useEffect(() => {
    // const token = localStorage.getItem("minerToken");
    const token = localStorage.getItem("userToken");
    if (accountAddress && !token) {
      getMessage(accountAddress);
    }
  }, [accountAddress]);
  return (
    <div className={"header-page"}>
      <div className={"show-flex-box-r align-center"}>
        {/* <img onClick={openWebSite} className={"logo"} src={Logo} alt=""/> */}
        <img className={"logo"} src={Logo} alt="" />
      </div>
      <div className={"right-container"}>
        <div onClick={() => open()} className={"cursor-pointer connect-button"}>
          {address && account.address
            ? getStr(account.address, 4, 4)
            : "Connect"}
        </div>
      </div>
    </div>
  );
};
