import "./buyBitlifeDialog.scss";
import React, { useEffect, useRef, useState } from "react";
interface createBitAgentProps {
  title: string;
  btnLabel: string;
  closeDialog: () => void;
  clickNext: () => void;
  dataBitlifeQuantity: number;
  dataBitlifePrice: number;
  dataBitlifeUrl: string;
}
export const BuyBitlifeDialog: React.FC<createBitAgentProps> = ({
  title,
  btnLabel,
  closeDialog,
  clickNext,
  dataBitlifeQuantity,
  dataBitlifePrice,
  dataBitlifeUrl,
}) => {
  const clickConfirm = () => {
    if (btnLabel === "Confirm") {
      clickNext();
    }
  };
  return (
    <div className={"show-flex-box-r show-flex-center buy-bitlife-wrap"}>
      <div className={"show-flex-box-c buy-bitlife-content"}>
        <div className={"c-00E6FF f-28 mt-40 ml-40 dialog-title"}>{title}</div>
        <div
          className={"cursor-pointer close-icon"}
          onClick={closeDialog}
        ></div>
        <div
          className={"flex-1 mt-16 show-flex-box-c pl-40 pr-40 pt-20 content"}
        >
          <div className="title-des">
            You’re about to purchase{" "}
            {dataBitlifeQuantity ? dataBitlifeQuantity : 0} units of 4×4 BitLife
            for{" "}
            {dataBitlifeQuantity && dataBitlifePrice
              ? dataBitlifePrice * dataBitlifeQuantity
              : 0}{" "}
            USDT
          </div>
          <div className="bitlife-wrap">
            <img src={dataBitlifeUrl} alt="" srcSet="" />
          </div>
          <ul className="bitlife-des-list">
            <li>
              The 4×4 BitLife are available for pre-sale. Once your order is
              confirmed, the units will be distributed to your payment address.
            </li>
            <li>
              Please note that sales are final and refunds are not available
              after purchase. Make sure you’re selecting the correct BitLife
              model.
            </li>
            <li>Please use USDT on the BSC network for payment.</li>
            <li>
              Once payment is made, you can track your order status under "My
              Orders." If you have any questions, feel free to contact us.
            </li>
          </ul>
          <div className={"show-flex-box-r show-flex-sa btn-list"}>
            <div
              className={
                "show-flex-box-r show-flex-center f-18 color-white cursor-pointer cancel-btn"
              }
              onClick={closeDialog}
            >
              Cancel
            </div>
            <div
              className={
                "show-flex-box-r show-flex-center f-18 color-white cursor-pointer confirm-btn"
              }
              onClick={clickConfirm}
            >
              {btnLabel}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
